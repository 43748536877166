'use strict'

###*
 # @ngdoc service
 # @name mundoAdmin.contacts.factory:ContactManager

 # @description

###
angular
  .module 'mundoAdmin.contacts'
  .factory 'ContactManager', [
    'MundoDefaultManager'
    'TenantManager'
    'ContactTypeManager'
    'UserManager'
    '$mdDialog'
    'LocationManager'
    'ContactDataContextManager'
    'ContactRelationTypeManager'
    'ContactDataManager'
    'UserContextManager'
    (
      MundoDefaultManager
      TenantManager
      ContactTypeManager
      UserManager
      $mdDialog
      LocationManager
      ContactDataContextManager
      ContactRelationTypeManager
      ContactDataManager
      UserContextManager
    ) ->
      ContactManagerBase = new MundoDefaultManager()
      ContactManagerBase.templateOptions = {
        folder: 'mundo-admin/contacts/views/data'
      }
  #   base
      ContactManagerBase.setUrl('contacts')
      ContactManagerBase.setAutoTenant()
      ContactManagerBase.setNewObject(['label', 'firstName', 'lastName', 'contactType', 'user'])
      ContactManagerBase.setUpdateObject(['label', 'firstName', 'lastName', 'contactType', 'user'])
      ContactManagerBase.setSearchFields(['label', 'tenant.label', 'contactType.label'])

      ContactManagerBase.new = (data) ->
        if data.user? and data.user.user?
          data.user = data.user.user.id

        @transport.all(@getUrl()).post(_.pick(data, @getNewObject()), {}, autoTenant: @conf.autoTenant)

      ContactManagerBase.update = (id, data) ->
        if data.user? and data.user.user?
          data.user = data.user.user.id

        @transport.one(@getUrl(), id).customPUT(_.pick(data, @getUpdateObject()))

      ContactManagerBase.getNewForm = ->
        [
          key: 'label'
          name: 'label'
          type: 'input'
          templateOptions:
            label: 'Label'
            placeholder: 'label'
            required: true
        ,
          key: 'firstName'
          name: 'firstName'
          type: 'input'
          templateOptions:
            label: 'First Name'
            placeholder: 'First Name'
            required: false
        ,
          key: 'lastName'
          name: 'lastName'
          type: 'input'
          templateOptions:
            label: 'Last Name'
            placeholder: 'Last Name'
            required: false
        ,
          key: 'contactType'
          name: 'contactType'
          type: 'select'
          templateOptions:
            label: 'Contact type'
            placeholder: 'Contact type'
            required: true
            labelProp: "label"
            valueProp: "id"
            options: ContactTypeManager.getFullList({'sort' : 'label,ASC'}).$object
        ,
          key: 'user'
          name: 'user'
          type: 'autocomplete'
          templateOptions:
            label: 'User'
            placeholder: 'User'
            required: false
            displayValue: "user.username"
            options: UserContextManager.getFullList({'sort' : 'label,ASC', 'filter[isGroup]' : 'isGroup,FALSE'}).$object

        ]

      ContactManagerBase.getEditForm = (data) ->
        [
          key: 'label'
          name: 'label'
          type: 'input'
          defaultValue: data.label
          templateOptions:
            label: 'Label'
            placeholder: 'label'
            required: true
        ,
          key: 'firstName'
          name: 'firstName'
          type: 'input'
          defaultValue: data.firstName
          templateOptions:
            label: 'First Name'
            placeholder: 'First Name'
            required: false
        ,
          key: 'lastName'
          name: 'lastName'
          type: 'input'
          defaultValue: data.lastName
          templateOptions:
            label: 'Last Name'
            placeholder: 'Last Name'
            required: false
        ,
          key: 'contactType'
          name: 'contactType'
          type: 'select'
          defaultValue: data.contactType.id
          templateOptions:
            label: 'Contact type'
            placeholder: 'Contact type'
            required: true
            labelProp: "label"
            valueProp: "id"
            options: ContactTypeManager.getFullList({'sort' : 'label,ASC'}).$object
        ,
          key: 'user'
          name: 'user'
          type: 'autocomplete'
          defaultValue: if data.user then data else ''
          templateOptions:
            label: 'User'
            placeholder: 'User'
            required: false
            displayValue: "user.username"
            options: UserContextManager.getFullList({'sort' : 'label,ASC', 'filter[isGroup]' : 'isGroup,FALSE'}).$object

        ]

      ContactManagerBase.getListSchema = ->
        [
          key: 'label'
          title: 'Label'
          sort: 'label'
        # ,
        #   key: 'tenant.label'
        #   title: 'datatable.label.tenant'
        #   sort: 'tenant.label'
        #   hideInList: true
        ,
          key: 'contactType.translatedLabel'
          title: 'datatable.label.type'
          sort: 'contactType.label'
        ,
          key: 'createdAt'
          title: 'datatable.label.created.at'
          sort: 'createdAt'
          type: 'dateTime'
          hideInList: true
        ,
          key: 'firstName'
          title: 'datatable.label.first.name'
          hideInList: true
        ,
          key: 'lastName'
          title: 'datatable.label.last.name'
          hideInList: true
        ]

      ContactManagerBase.getDetailTemplateUrl = ->
        'mundo-admin/contacts/views/contact-detail.tpl.html'

  #   locations
      ContactManagerBase.setObject('contactLocation', ['contactDataContext', 'location'])
      ContactManagerBase.getLocations = (id)->
        @transport.one(@conf.url, id).get().then (data) ->
          data.contactLocations

      ContactManagerBase.addLocation = (id, data) ->
        @transport.one(@conf.url, id).all('locations').post(_.pick(data, @getObject('contactLocation')))

      ContactManagerBase.removeLocation = (unitId, contactId) ->
        @transport.one(@conf.url, unitId).one('locations', contactId).remove()

      ContactManagerBase.getLocationForm = ->
        [
          key: 'contactDataContext'
          name: 'contactDataContext'
          type: 'select'
          templateOptions:
            label: 'contactDataContext'
            placeholder: 'contactDataContext'
            required: false
            labelProp: "label"
            valueProp: "id"
            options: ContactDataContextManager.getList().$object
        ,
          key: 'location'
          name: 'location'
          type: 'select'
          templateOptions:
            label: 'location'
            placeholder: 'location'
            required: false
            labelProp: "street"
            valueProp: "id"
            options: LocationManager.getList().$object
        ]

      addLocation =
        [
          icon: 'location_on'
          text: 'datatable.tooltips.addlocation'
          action: (id) ->
            $mdDialog.show
              templateUrl: 'mundo-admin/contacts/views/contact-locations.modal.tpl.html'
              controller: 'ContactLocationsCtrl'
              controllerAs: 'ctrl'
              locals:
                id: id
        ]

      ContactManagerBase.addExtraAction(addLocation)

  #   relations
      ContactManagerBase.setObject('contactRelations', ['contactRelationType', 'parentContact', 'childContact'])

      ContactManagerBase.addRelation = (data) ->
        @transport.one(@conf.url).all('relations').post(_.pick(data, @getObject('contactRelations')))

      ContactManagerBase.getRelationForm = () ->
        [
          key: 'contactRelationType'
          name: 'contactRelationType'
          type: 'select'
          templateOptions:
            label: 'Relation Type'
            placeholder: 'Relation Type'
            required: true
            labelProp: "label"
            valueProp: "id"
            options: ContactRelationTypeManager.getList().$object
        ,
          key: 'parentContact'
          name: 'parentContact'
          type: 'select'
          templateOptions:
            label: 'Parent'
            placeholder: 'Parent'
            required: true
            labelProp: "label"
            valueProp: "id"
            options: @getList().$object
        ,
          key: 'childContact'
          name: 'childContact'
          type: 'select'
          templateOptions:
            label: 'Child'
            placeholder: 'child'
            required: true
            labelProp: "label"
            valueProp: "id"
            options: @getList().$object
        ]

      addRelation =
        [
          icon: 'group_add'
          text: 'Add a contact relation'
          action: () ->
            $mdDialog.show
              templateUrl: 'mundo-admin/contacts/views/contact-relations.modal.tpl.html'
              controller: 'ContactRelationsCtrl'
              controllerAs: 'ctrl'
        ]

      ContactManagerBase.addTableAction(addRelation)

  #   data
      ContactManagerBase.addData = (id, data) ->
        ContactDataManager.new(id, data)

      ContactManagerBase.removeData = (contactId, dataId) ->
        ContactDataManager.removeData(contactId, dataId)

      ContactManagerBase.getDataFieldsByType = (type) ->
        ContactDataManager.getExtraFormByType(type)

      addData =
        [
          icon: 'description'
          text: 'datatable.tooltips.addContactData'
          action: (id) ->
            $mdDialog.show
              templateUrl: 'mundo-admin/contacts/views/contact-data.modal.tpl.html'
              controller: 'ContactDataCtrl'
              controllerAs: 'ctrl'
              locals:
                id: id
        ]

      ContactManagerBase.addExtraAction(addData)

      ContactManagerBase.editPermission = 'manage all MundoMosaContactBundle:Contact entities'

      ContactManagerBase
  ]
